var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c("div", { staticClass: "admin-edit-task" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col s4" }, [
            _c(
              "label",
              { staticClass: "device-label", attrs: { for: "sim_id" } },
              [_vm._v("Task ID")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.taskInternal.id,
                  expression: "taskInternal.id"
                }
              ],
              staticClass: "text-field w-input",
              attrs: {
                type: "text",
                disabled: "",
                maxlength: "256",
                placeholder: "SIM Id",
                id: "sim_id",
                disabled: ""
              },
              domProps: { value: _vm.taskInternal.id },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.taskInternal, "id", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "col-4" }, [
            _c(
              "label",
              { staticClass: "device-label", attrs: { for: "sim_status" } },
              [_vm._v("Status")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.taskInternal.status,
                  expression: "taskInternal.status"
                }
              ],
              staticClass: "text-field w-input",
              attrs: {
                type: "text",
                disabled: "",
                maxlength: "256",
                name: "Registration",
                "data-name": "Registration",
                placeholder: "Status",
                id: "sim_status"
              },
              domProps: { value: _vm.taskInternal.status },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.taskInternal, "status", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "col-4" }, [
            _c(
              "label",
              { staticClass: "device-label", attrs: { for: "sim_iccid" } },
              [_vm._v("Created")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.taskInternal.created,
                  expression: "taskInternal.created"
                }
              ],
              staticClass: "text-field w-input",
              attrs: {
                type: "text",
                disabled: "",
                maxlength: "256",
                name: "Registration",
                "data-name": "Registration",
                placeholder: "ICCID",
                id: "sim_iccid"
              },
              domProps: { value: _vm.taskInternal.created },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.taskInternal, "created", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "col-4" }, [
            _c(
              "label",
              { staticClass: "device-label", attrs: { for: "sim_iccid" } },
              [_vm._v("Expiry")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.taskInternal.expiry,
                  expression: "taskInternal.expiry"
                }
              ],
              staticClass: "text-field w-input",
              attrs: {
                type: "text",
                disabled: "",
                maxlength: "256",
                name: "Registration",
                "data-name": "Registration",
                placeholder: "ICCID",
                id: "sim_iccid"
              },
              domProps: { value: _vm.taskInternal.expiry },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.taskInternal, "expiry", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "col-4" }, [
            _c(
              "label",
              { staticClass: "device-label", attrs: { for: "sim_iccid" } },
              [_vm._v("Last Updated")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.taskInternal.last_update,
                  expression: "taskInternal.last_update"
                }
              ],
              staticClass: "text-field w-input",
              attrs: {
                type: "text",
                disabled: "",
                maxlength: "256",
                name: "Registration",
                "data-name": "Registration",
                placeholder: "ICCID",
                id: "sim_iccid"
              },
              domProps: { value: _vm.taskInternal.last_update },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.taskInternal, "last_update", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "col-4" }, [
            _c(
              "label",
              { staticClass: "device-label", attrs: { for: "sim_iccid" } },
              [_vm._v("Owner")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.taskInternal.owner_email,
                  expression: "taskInternal.owner_email"
                }
              ],
              staticClass: "text-field w-input",
              attrs: {
                type: "text",
                disabled: "",
                maxlength: "256",
                name: "Registration",
                "data-name": "Registration",
                placeholder: "ICCID",
                id: "sim_iccid"
              },
              domProps: { value: _vm.taskInternal.owner_email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.taskInternal, "owner_email", $event.target.value)
                }
              }
            })
          ]),
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "label",
                { staticClass: "device-label", attrs: { for: "sim_iccid" } },
                [_vm._v("Task Data")]
              ),
              _c("b-form-textarea", {
                staticClass: "text-input",
                attrs: {
                  type: "text",
                  rows: "6",
                  id: "rawJSON",
                  placeholder: "Task Data (Results)"
                },
                model: {
                  value: _vm.jsonResult,
                  callback: function($$v) {
                    _vm.jsonResult = $$v
                  },
                  expression: "jsonResult"
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "spacing" })
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }