<template>
  <div class="admin-edit-task" v-if="!loading">
        <div class="row">
          <div class=" col s4">
            <label for="sim_id" class="device-label">Task ID</label>
            <input type="text" v-model="taskInternal.id" class="text-field w-input" disabled
                   maxlength="256" placeholder="SIM Id" id="sim_id" disabled>
          </div>
          <div class="col-4">
            <label for="sim_status" class="device-label">Status</label>
            <input type="text" v-model="taskInternal.status" class="text-field w-input" disabled
                   maxlength="256" name="Registration" data-name="Registration" placeholder="Status" id="sim_status"
            />
          </div>
          <div class="col-4">
            <label for="sim_iccid" class="device-label">Created</label>
            <input type="text" v-model="taskInternal.created" class="text-field w-input" disabled
                   maxlength="256" name="Registration" data-name="Registration" placeholder="ICCID" id="sim_iccid">
          </div>
          <div class="col-4">
            <label for="sim_iccid" class="device-label">Expiry</label>
            <input type="text" v-model="taskInternal.expiry" class="text-field w-input" disabled
                   maxlength="256" name="Registration" data-name="Registration" placeholder="ICCID" id="sim_iccid">
          </div>
          <div class="col-4">
            <label for="sim_iccid" class="device-label">Last Updated</label>
            <input type="text" v-model="taskInternal.last_update" class="text-field w-input" disabled
                   maxlength="256" name="Registration" data-name="Registration" placeholder="ICCID" id="sim_iccid">
          </div>
          <div class="col-4">
            <label for="sim_iccid" class="device-label">Owner</label>
            <input type="text" v-model="taskInternal.owner_email" class="text-field w-input" disabled
                   maxlength="256" name="Registration" data-name="Registration" placeholder="ICCID" id="sim_iccid">
          </div>
          <div class="col-12">
            <label for="sim_iccid" class="device-label">Task Data</label>
            <b-form-textarea
              v-model="jsonResult"
              type="text"
              rows="6"
              id="rawJSON"
              placeholder="Task Data (Results)"
              class="text-input"
            ></b-form-textarea>
          </div>
          <div class="spacing"></div>
        </div>



  </div>
</template>

<script>
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
import * as AlertHelper from '../helpers/AlertHelper'

export default {
  name: 'admin-edit-task',
  components: {
  },
  props: {
    isModal: Boolean,
    modal: String,
    task: Object
  },
  data: function () {
    return {
      loading: true,
      showButtons: true,
      selectedDevices: null,
      taskInternal: {
      },
      taskData: {},
      statusOptions: [
        { value: '0', text: 'Issued' },
        { value: '1', text: 'Active' },
        { value: '2', text: 'Inactive' },
        { value: '3', text: 'Error' }
      ]
    }
  },
  async created () {
    if (this.task) {
      console.log(this.task)
      this.taskInternal = { ...this.task }
      this.taskData = await this.getTaskData(this.task.id)
    }
    // Hide Controls so the parent modal can display them
    if (this.isModal) {
      this.showButtons = false
    }
    this.loading = false
  },
  methods: {
    changeStatus: async function (newStatus) {
      console.log(newStatus)
      let res = await DataProvider.adminUpdateSIM(this.simCardInternal.sim_id, newStatus)
      if (res.success) {
        console.log(res)
        if (res.data.updates.status) {
          AlertHelper.successToast('SIM Status Updated', 'Changes Saved!')
        } else {
          ErrorHelper.displayGeneralWarningToast('SIM status update completed, but the server did not confirm ' +
            'the change! Probably a Comms error with the SIM provider' +
            '', 'SIM Status Update Incomplete')
        }
        this.$emit('save')
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
    },
    async getTaskData (taskId) {
      let resp = await DataProvider.getTask(taskId)
      console.log('resp": ', resp)
      if (resp.success) {
        return resp.data
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    }
  },
  computed: {
    jsonResult() {
      return JSON.stringify(this.taskData, null, '\t')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .admin-edit-task {
    color: $text-color-invert;
  }

  .device-label{
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .device-code{
    color: $text-color-invert;
    margin-left: 5%;
  }

  .toolbar {
    display: flex;
    flex-direction: row;
  }

  .icon-button, .icon-button-danger{
    font-size: 1.9rem;
  }

  .footer{
    width: 100%;
    bottom: 2%;
    display: flex;
    justify-content: flex-end;
    margin: 0 1% 0 1%;
  }

  .icon_select_color {
    border: solid $theme-color-primary-3;
  }

  .notification-container {
    display: flex;
    flex-direction: column;
    max-height: 50vh;
    overflow-y: auto;
  }

  .notification-item{
    background: $theme-color-background-1;
  }

</style>
