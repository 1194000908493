<template>
  <div class="admin-task">
    <div class="toolbar justify-content-between">
      <div>
        <i class="icon-button" :class="$config.icons.general.back" v-b-tooltip title="Back to Admin" @click="clickBack"></i>
      </div>
      <div>
        <i class="icon-button" :class="$config.icons.general.refresh" v-b-tooltip title="Refresh" @click="refreshList"></i>
<!--        <i class="mx-1 icon-button" :class="$config.icons.user.add" v-b-tooltip title="Add a User" @click="createUser"></i>-->
<!--        <router-link to="/admin/users/bulkadd"><i class="mx-1 icon-button" :class="$config.icons.user.bulkAdd" v-b-tooltip title="Upload Users"></i></router-link>-->
        <!--        <i class="fa fa-user-times mx-1 icon-button" v-b-tooltip title="Delete Selected Users" @click="deleteSelected"></i>-->
      </div>
    </div>
    <div class="list-containter">
      <b-card>
        <b-card-header><h2>Task Admin</h2></b-card-header>
        <b-card-body>
          <admin-task-list ref="adminProductList"></admin-task-list>
        </b-card-body>
      </b-card>
    </div>
    <!--    Create Edit Modal Template-->
<!--    <b-modal id="modal-edit-product" centered class="modal-content" size="xl" hide-footer title="Edit Product">-->
<!--      <admin-edit-product is-modal modal="modal-edit-product" v-on:save="refreshList()"></admin-edit-product>-->
<!--    </b-modal>-->
  </div>
</template>

<script>

import AdminTaskList from '@/components/admin/AdminTaskList.vue'

export default {
  name: 'admin-tasks',
  components: {
    AdminTaskList
  },
  data: function () {
    return {
      selectedUsers: []
    }
  },
  methods: {
    clickBack: function () {
      this.$router.push({ path: '/admin' })
    },
    selectChange: function (newSelection) {
      this.selectedUsers = newSelection
    },
    createUser: function () {
      this.$bvModal.show('modal-create-user')
    },
    refreshList: function () {
      this.$refs.adminProductList.refresh()
    }
    // deleteSelected: async function () {
    //   if (this.selectedUsers.length === 0) {
    //     ErrorHelper.displayGeneralErrorToast('You don\'t have ay users selected to delete!', 'No Users Selected')
    //     return
    //   }
    //
    //   let res = await this.$bvModal.msgBoxConfirm(`Are you sure you want to delete ${this.selectedUsers.length} Users?`, {
    //     title: 'Confirm Delete',
    //     okVariant: 'danger',
    //     centered: true
    //   })
    //   if (res) {
    //     let data = await DataProvider.deleteUser(this.selectedUsers)
    //     if (data.success) {
    //       // Reload Data in View
    //       this.refreshList()
    //     } else {
    //       ErrorHelper.displayDataErrorToast(data)
    //     }
    //   }
    // }
  }
}
</script>

<style scoped lang="scss">
  @import '../../variables';

  .admin-task {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2%;
    overflow-y: auto;
  }

  .list-containter {
    background: $theme-color-background-4;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
  }

  .toolbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 3rem;
  }

</style>
