var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "task-list" },
    [
      _c("loading-box", { attrs: { loading: _vm.loading } }),
      _c(
        "div",
        { staticClass: "filter-bar" },
        [
          _c("div", { staticClass: "green-label" }, [_vm._v("Filters")]),
          _c("search-filters", {
            ref: "searchFilters",
            attrs: { filters: _vm.filters },
            on: { change: _vm.changeFilter, "press-enter": _vm.applyFilter }
          }),
          _c(
            "div",
            [
              _c("b-button", { on: { click: _vm.applyFilter } }, [
                _vm._v("Apply")
              ]),
              _c("b-button", { on: { click: _vm.resetFilters } }, [
                _vm._v("Reset")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "green-divider" }),
      _c("b-table", {
        staticClass: "bv-table",
        attrs: {
          striped: "",
          hover: "",
          items: _vm.taskList,
          fields: _vm.taskFields,
          "select-mode": "single",
          "selected-variant": "success",
          "th-class": "bv-table-header",
          "current-page": _vm.currentPage,
          "per-page": _vm.perPage,
          filter: _vm.filterMethod === "local" ? _vm.filter : undefined,
          filterIncludedFields: _vm.filterFields
        },
        scopedSlots: _vm._u([
          {
            key: "cell(published)",
            fn: function(row) {
              return [
                _c("b-form-checkbox", {
                  on: {
                    change: function($event) {
                      return _vm.togglePublish(row.item, $event)
                    }
                  },
                  model: {
                    value: row.item.published,
                    callback: function($$v) {
                      _vm.$set(row.item, "published", $$v)
                    },
                    expression: "row.item.published"
                  }
                })
              ]
            }
          },
          {
            key: "cell(actions)",
            fn: function(row) {
              return [
                _c("i", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true }
                    }
                  ],
                  staticClass: "row-icon-button row-action",
                  class: _vm.$config.icons.general.edit,
                  attrs: { title: "Edit Product" },
                  on: {
                    click: function($event) {
                      return _vm.taskActionEdit(row.item)
                    }
                  }
                }),
                _c("i", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true }
                    }
                  ],
                  staticClass:
                    "fa fa-times-circle row-icon-button-danger row-action ml-3",
                  attrs: { title: "Does nothing!" },
                  on: {
                    click: function($event) {
                      return _vm.userActionDelete(row.item)
                    }
                  }
                })
              ]
            }
          }
        ])
      }),
      _c("div", { staticClass: "green-divider" }),
      _c("div", { staticClass: "footer" }, [
        _c("div", { staticClass: "table-results" }, [
          _vm._v(" Results: " + _vm._s(_vm.totalRows) + " ")
        ]),
        _c(
          "div",
          { staticClass: "page-bar" },
          [
            _c("b-form-select", {
              staticClass: "page-select",
              attrs: {
                variant: "dark",
                id: "perPageSelect",
                size: "sm",
                options: _vm.pageOptions
              },
              model: {
                value: _vm.perPage,
                callback: function($$v) {
                  _vm.perPage = $$v
                },
                expression: "perPage"
              }
            }),
            _c("b-pagination", {
              staticClass: "page-buttons",
              attrs: {
                variant: "dark",
                "total-rows": _vm.taskList.length,
                "per-page": _vm.perPage,
                align: "fill",
                size: "sm"
              },
              model: {
                value: _vm.currentPage,
                callback: function($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-edit-task",
            centered: "",
            size: "xl",
            title: "Edit Task",
            "no-close-on-backdrop": "",
            "hide-footer": ""
          }
        },
        [
          _c("AdminEditTask", { attrs: { task: _vm.currentTask } }),
          _c(
            "b-button",
            {
              staticClass: "button btn",
              on: {
                click: function($event) {
                  return _vm.$bvModal.hide("modal-edit-task")
                }
              }
            },
            [_vm._v("Close")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }